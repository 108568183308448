<template>
    <div class="container">

        <b-card style="width:fit-content">
            <b-col cols="12">
                <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="none" to="/marketplace" v-b-tooltip.hover
                    title="Go Back to the Script List">
                    <feather-icon icon="ArrowLeftCircleIcon" size="25" class="d-inline text-warning" />
                </b-button>
                <h3 class="d-inline">Full Details of {{ name }}</h3>
            </b-col>
        </b-card>


        <b-row>
            <b-col class="col-sm-4">
                <b-card id="appInfoLabel">
                    <h4 class="text-center">Item's Informations</h4>
                </b-card>
            </b-col>
        </b-row>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-8">
                <b-card id="appInfoMain">
                    <div class="image-container">
                        <div class="image-wrapper">
                            <div v-for="(screen, index) in screens" :key="index" class="image-item">
                                <img :src="screen" alt="Screenshot" loading="lazy" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div class="h3 text-dark">Description : </div>
                        <p class="h5 description-text text-dark">
                            {{ description }}
                        </p>
                    </div>
                </b-card>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="fixed-card">
                    <b-card class="container">
                        <div class="row justify-content-between">
                            <div class="col-sm-7">
                                <p>Regular Licenece</p>
                            </div>
                            <div class="col-sm-5">
                                <b>FREE <Feather-icon icon="GiftIcon" /></b>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row justify-content-between mb-2 ">
                            <div class="col-sm-12 mb-1">
                                <div class="h4 text-dark">
                                    <feather-icon icon="CheckIcon" /> SIZE : {{ fileSize | formatFileSize }}
                                </div>
                            </div>
                            <div class="col-sm-12 mb-1">
                                <div class="h4 text-dark">
                                    <feather-icon icon="CheckIcon" /> Creation Date : {{ createdAt }}
                                </div>
                            </div>
                            <div class="col-sm-12 mb-1">
                                <div class="h4 text-dark">
                                    <feather-icon icon="CheckIcon" /> Downloads: {{ nbDownloads }}
                                </div>
                            </div>

                        </div>

                        <b-button @click="downloadZip(zip_urls)" v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="success"
                            block>
                            Download File <feather-icon icon="DownloadIcon" />
                        </b-button>

                        <!-- <hr class="my-1" />
                        <div>
                            <h2>Rate the Script</h2>
                            <div class="star-rating">
                                <span v-for="rating in 5" :key="rating" @click="setRating(rating)"
                                    :class="{ active: rating <= selectedRating }">
                                    &#9733;
                                </span>
                            </div>
                            <p v-if="selectedRating">
                                <i v-if="selectedRating < 3">
                                    <feather-icon icon="FrownIcon" size=30 class="ratedIcon" />
                                </i>
                                <i v-else-if="selectedRating == 3">
                                    <feather-icon icon="MehIcon" size=30 class="ratedIcon" />
                                </i>
                                <i v-else>
                                    <feather-icon icon="SmileIcon" size=30 class="ratedIcon" />
                                </i>
                                You've rated the script with {{ selectedRating }} star(s)!
                                <b-form-textarea rows="4" v-model="feedback" class="my-2"
                                    placeholder="We will be thrilled to hear your feedback">
                                </b-form-textarea>
                                <b-button variant="outline-success" @click="saveReview">Submit Your Review</b-button>
                            </p>
                        </div> -->
                    </b-card>
                    <b-card-group deck>
                        <b-card title="TECHNOLOGIES">
                            <b-row v-for="(tagChunk, index) in tagsInChunks" :key="index">
                                <b-col cols="2" v-for="tag in tagChunk" :key="tag">
                                    <!-- @click="goToTagFilterPage(tag)" -->
                                    <b-badge>{{ tag }}</b-badge>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-card-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BContainer,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BCard,
    BRow,
    BCol,
    VBTooltip,
    BButtonGroup,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    VBModal,
    BCardGroup,
    BFormFile,
    BCardText,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import { getAccessTokenFromCookie } from "@/auth/utils";

export default {
    components: {
        BTable,
        BAvatar,
        'b-tooltip': VBTooltip,
        BCard,
        BImg,
        BButtonGroup,
        BCardGroup,
        BBadge,
        BFormTextarea,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BFormFile,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BModal,
        VBModal,
        BCardText,
        BContainer,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            id: null,
            name: null,
            image_url: null,
            screens: [],
            description: null,
            zip_urls: [],
            tags: [],
            nbDownloads: null,
            createdAt: null,
            fileSize: null,
            selectedRating: 0,
            feedback: null,
        }
    },
    computed: {
        tagsInChunks() {
            const chunkSize = 3;
            const tagsCopy = [...this.tags];
            const chunks = [];

            while (tagsCopy.length) {
                chunks.push(tagsCopy.splice(0, chunkSize));
            }

            return chunks;
        },
    },
    filters: {
        formatFileSize(sizeInBytes) {
            if (sizeInBytes === null || isNaN(sizeInBytes)) return 'N/A';

            const units = ['B', 'KB', 'MB', 'GB', 'TB'];
            let index = 0;
            while (sizeInBytes >= 1024 && index < units.length - 1) {
                sizeInBytes /= 1024;
                index++;
            }

            return `${sizeInBytes.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${units[index]}`;
        },
    },
    mounted() {
      const data = getAccessTokenFromCookie();
        const userType = data[2].role;
        if (userType !== "user") {
            router.push({ name: "error-404" })
        }
        this.id = this.$route.params.id;
        this.getDetailScript();

    },
    methods: {
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        goToTagFilterPage(tagName) {
            router.push({ name: "ScriptsByTag", params: { tag: tagName } })
        },
        setRating(rating) {
            this.selectedRating = rating;
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleDateString();
        },
        getDetailScript() {
            axios.post('/get-mrktp-scripts-by-id', {
                id: this.id
            }).then((response) => {
                if (response.data.status === "success") {
                    this.name = response.data.script.name;
                    this.createdAt = response.data.script.created_at;
                    this.createdAt = this.formatDate(this.createdAt)
                    this.image_url = response.data.script.image_url;
                    this.description = response.data.script.description;
                    this.tags = response.data.tags;
                    this.nbDownloads = response.data.script.downloads;
                    this.zip_urls = response.data.script.zip_urls;
                    this.fileSize = response.data.zip_file_size
                    this.screens = response.data.script.screens;
                    var cleanedData = this.screens.replace(/\\\//g, '/');
                    this.screens = JSON.parse(cleanedData);
                } else if (response.data.status === "failed") {
                    this.showToast(
                        "danger",
                        "AlertTriangleIcon",
                        "Error",
                        response.data.message
                    );
                    router.push({ name: "marketplace" })

                }

            }).catch(error => {
                console.error(error);
            })
        },
        saveReview() {
            this.selectedRating = false;
        },
        downloadZip(link) {
            window.open(link, '_blank');
            axios.post('/increment-download-number', {
                id: this.id
            })
                .then(({ data }) => {
                    this.nbDownloads = data.newDownloadNumber
                }).catch(error => {
                    console.error(error);
                })
        }

    }

}
</script>

<style scoped>
.image-container {
    overflow-x: auto;
    display: flex;
}

.image-wrapper {
    display: flex;
}

.image-item {
    flex: 0 0 auto;
    margin-right: 10px;
    max-width: 100%;
}

.image-item img {
    width: 100%;
    height: 70vh;
    /* max-width: 250px; */
}

.image-container::-webkit-scrollbar {
    width: 5px;
}

.image-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.image-container::-webkit-scrollbar-thumb {
    background-color: #050404;
    border-radius: 5px;
}

.image-container::-webkit-scrollbar-thumb:hover {
    background-color: rgb(7, 7, 60);
}

.image-container::-webkit-scrollbar-button {
    display: none;
}

del {
    font-size: 1.5rem;
    font-weight: 900;
}

#appInfoLabel {
    margin: 0;
    padding: 0;
}

#appInfoLabel .card-body {
    margin: 0;
    padding: 13px 0;
}

#appInfoMain,
#appInfoLabel {
    border-radius: 0;
}

.app-title {
    font-size: 3rem !important;
    margin-bottom: 26px;

}

.fixed-card {
    position: sticky;
    top: 20px;
}

.description-text {
    font-size: 16px;
    line-height: 1.9;
    font-weight: 400;
}

.star-rating {
    font-size: 24px;
}

.star-rating span {
    cursor: pointer;
}

.star-rating span.active {
    color: gold;
}

.ratedIcon {
    color: r#000;
}

div.app-content.content>div.content-wrapper>div>div>div:nth-child(1)>div {
    padding-block: 3px !important;
    padding-left: 3px !important;
}
</style>